<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <v-row dense>
      <v-col cols="5" class="pa-2">
        <div class="d-flex">
          <devotion-card :devotion="item.devotion" class="mr-2" />
          <worship-card :worship="item.worship" class="ml-2" />
        </div>
        <featured-video-card :featuredVideo="item.featuredVideo" class="mt-4" />
      </v-col>
      <v-col cols="4" class="pa-2">
        <calendar-card />
        <analytics-card class="mt-4" />
      </v-col>
      <v-col cols="3" class="pa-2">
        <announcement-card />
        <shortcut-card class="mt-4" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const miscellaneousAPIRepository = RepositoryFactory.get("miscellaneous-api");

const DevotionCard = () => import("../cards/DevotionCard.vue");
const WorshipCard = () => import("../cards/WorshipCard.vue");
const FeaturedVideoCard = () => import("../cards/FeaturedVideoCard.vue");
const CalendarCard = () => import("../cards/CalendarCard.vue");
const AnalyticsCard = () => import("../cards/AnalyticsCard.vue");
const AnnouncementCard = () => import("../cards/AnnouncementCard.vue");
const ShortcutCard = () => import("../cards/ShortcutCard.vue");

export default {
  components: {
    DevotionCard,
    WorshipCard,
    FeaturedVideoCard,
    CalendarCard,
    AnalyticsCard,
    AnnouncementCard,
    ShortcutCard
  },
  data: () => ({
    item: {}
  }),
  methods: {
    getUserHome() {
      let params = {
        date: moment().format("Y-MM-DD")
      };

      miscellaneousAPIRepository
        .userHome({ params })
        .then(response => {
          console.log({ response });

          let {
            data: { responseData: item }
          } = response;

          this.item = {
            devotion: item.devo || null,
            worship: item.worship_song || null,
            featuredVideo: item.daily_video || null
          };
        })
        .catch(error => {
          console.log({ error });
        });
    }
  },
  mounted() {
    this.getUserHome();
  }
};
</script>
